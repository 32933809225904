<template>
    <div id="gjsWrapper">
        <div class="panel__top border rounded" style="background-color: #fff">
            <div class="panel__switcher"></div>
<!--            <div class="panel__custom">-->
<!--&lt;!&ndash;                <b-field>&ndash;&gt;-->
<!--&lt;!&ndash;                    <b-slider v-model="value"></b-slider>&ndash;&gt;-->
<!--&lt;!&ndash;                </b-field>&ndash;&gt;-->
<!--            </div>-->
            <div class="panel__devices">
            </div>
            <div class="panel__basic-actions"></div>
        </div>
        <div class="editor-row">
            <div class="panel__left">
                <div class="blocks-container">
                    <div class="panel-headline">
                        Elemente
                    </div>
                </div>
                <div class="layers-container" style="display: none">
                    <div class="panel-headline">
                        Aufbau
                    </div>
                </div>
                <div class="styles-container"></div>
                <div class="traits-container" style="display: none">
                    <div class="panel-headline">
                        Einstellungen
                    </div>
                </div>
            </div>
            <div class="editor-canvas">
                <div id="gjs">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import grapesjs from 'grapesjs';
    import de from 'grapesjs/src/i18n/locale/de'

    import components from "./components";
    import panels from './panels'
    // import styleManager from "./styleManager";
    // import domComponents from "./domComponents";
    import blocks from "./blocks";
    localStorage.removeItem('gjs-assets')
    export default {
        name: "GrapesJS",
        data() {
            return {
                editor: null,
                value: 0,
            }
        },
        async mounted() {
            const app = this
            // await this.$store.getAssets()
            this.editor = grapesjs.init({
                i18n: {
                    locale: 'de',
                    localeFallback: 'de',
                    // detectLocale: 1,
                    // debug: 0,
                    messages: {
                        de
                    }
                },
                blockManager: {
                    appendTo: '.blocks-container',
                    blocks
                },
                layerManager: {
                    appendTo: '.layers-container'
                },
                // selectorManager: {
                //     appendTo: '.styles-container'
                // },
                traitManager: {
                    appendTo: '.traits-container',
                },
                deviceManager: {
                    devices: [{
                        name: 'Desktop',
                        width: '', // default size
                    }, {
                        name: 'Tablet',
                        width: '760px', // this value will be used on canvas width
                        widthMedia: '768px', // this value will be used in CSS @media
                    }, {
                        name: 'Mobile',
                        width: '360px', // this value will be used on canvas width
                        widthMedia: '480px', // this value will be used in CSS @media
                    }]
                },
                assetManager: {
                    assets: this.assets,
                    upload: 0,
                    embedAsBase64: 0,
                },
                // storageManager: {type: null},
                cssIcons: null,
                container: '#gjs',
                fromElement: true,
                height: '100%',
                width: 'auto',
                panels,
                // styleManager,
                plugins: [
                    components
                ],
                canvas:{
                    styles:[
                        'https://unpkg.com/tailwindcss@1.9.6/dist/tailwind.min.css',
                        '/css/landingpage.css',
                        '/css/editor.css',
                        '/css/sfp.css',
                    ]
                }
            });
            this.editor.Panels.addPanel({
                id: 'panel-top',
                el: '.panel__top',
            });
            this.editor.Panels.addPanel({
                id: 'basic-actions',
                el: '.panel__basic-actions',
                buttons: [
                    {
                        id: 'visibility',
                        active: true, // active by default
                        className: 'fa fa-vector-square',
                        command: 'sw-visibility', // Built-in command
                    }, {
                        id: 'save',
                        className: 'fa fa-save',
                        command(editor) {
                          let lp = app.$store.state.landingpages[0]
                          lp.elements = editor.getComponents()
                            app.$store.updateLandingpage(
                              lp
                            )
                            // editor.Modal.setTitle('Components JSON')
                            //     .setContent(`<textarea style="width:100%; height: 250px;">
                            //       ${JSON.stringify(editor.getComponents())}
                            //     </textarea>`)
                            //     .open();
                        },
                    }
                ],
            });
            this.editor.Commands.add('toggle-fullscreen', editor => {
                    if (typeof editor === 'undefined') return
                    if (
                        document.fullscreenElement ||
                        document.webkitFullscreenElement ||
                        document.mozFullScreenElement ||
                        document.msFullscreenElement
                    ) {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                        }
                    } else {
                        const element = document.getElementById('gjsWrapper');
                        if (element.requestFullscreen) {
                            element.requestFullscreen();
                        } else if (element.mozRequestFullScreen) {
                            element.mozRequestFullScreen();
                        } else if (element.webkitRequestFullscreen) {
                            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                        } else if (element.msRequestFullscreen) {
                            element.msRequestFullscreen();
                        }
                    }
            });
            this.editor.Commands.add('show-blocks', {
                getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
                getLayersEl(row) { return row.querySelector('.blocks-container') },

                run(editor, sender) {
                  if (typeof sender === 'undefined') return
                    const lmEl = this.getLayersEl(this.getRowEl(editor));
                    lmEl.style.display = '';
                },
                stop(editor, sender) {
                  if (typeof sender === 'undefined') return
                    const lmEl = this.getLayersEl(this.getRowEl(editor));
                    lmEl.style.display = 'none';
                },
            });
            this.editor.Commands.add('show-layers', {
                getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
                getLayersEl(row) { return row.querySelector('.layers-container') },

                run(editor, sender) {
                  if (typeof sender === 'undefined') return
                    const lmEl = this.getLayersEl(this.getRowEl(editor));
                    lmEl.style.display = '';
                },
                stop(editor, sender) {
                  if (typeof sender === 'undefined') return
                    const lmEl = this.getLayersEl(this.getRowEl(editor));
                    lmEl.style.display = 'none';
                },
            });
            // this.editor.Commands.add('show-styles', {
            //     getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
            //     getStyleEl(row) { return row.querySelector('.styles-container') },
            //
            //     run(editor, sender) {
            //         const smEl = this.getStyleEl(this.getRowEl(editor));
            //         smEl.style.display = '';
            //     },
            //     stop(editor, sender) {
            //         const smEl = this.getStyleEl(this.getRowEl(editor));
            //         smEl.style.display = 'none';
            //     },
            // });
            this.editor.Commands.add('show-traits', {
                getTraitsEl(editor) {
                    const row = editor.getContainer().closest('.editor-row');
                    return row.querySelector('.traits-container');
                },
                run(editor, sender) {
                  if (typeof sender === 'undefined') return
                    this.getTraitsEl(editor).style.display = '';
                },
                stop(editor, sender) {
                  if (typeof sender === 'undefined') return
                    this.getTraitsEl(editor).style.display = 'none';
                },
            });
            this.editor.Commands.add('set-device-desktop', {
                run: editor => editor.setDevice('Desktop')
            });
            this.editor.Commands.add('set-device-tablet', {
                run: editor => editor.setDevice('Tablet')
            });
            this.editor.Commands.add('set-device-mobile', {
                run: editor => editor.setDevice('Mobile')
            });
            this.editor.Commands.add('clear', {
                run: editor => {
                    editor.DomComponents.clear()
                }
            });
            this.editor.Commands.add('experiment', {
                run: editor => {
                    editor.DomComponents.addComponent({type: 'header'})
                    editor.DomComponents.addComponent({type: 'body'})
                    editor.DomComponents.addComponent({type: 'footer'})
                }
            });
            this.editor.Commands.add('create', {
                run: editor => {
                    editor.DomComponents.addComponent({type: 'header'})
                    editor.DomComponents.addComponent({type: 'body'})
                    editor.DomComponents.addComponent({type: 'footer'})
                }
            });
            this.editor.I18n.setLocale('de')
            // domComponents(this.editor)
            // if(this.$store.state.landingpages[0].elements.length === 0) this.editor.Commands.run('create');

        },
        computed:{
            assets(){
                //if(Array.isArray(this.$root.env.account.media)) this.$root.env.account.media.forEach(m => r.push(m.url))
                return this.$store.state.assets.map(a => a.url)
            }
        }
    }
</script>

<style lang="css">
    @import '../../../node_modules/grapesjs/dist/css/grapes.min.css';
    /*@import "grapesjs/src/styles/scss/main.scss";*/

    #gjsWrapper{
        height: 100%;
    }

    /* Let's highlight canvas boundaries */
    #gjs {
        border: none;
    }

    /* Reset some default styling */
    .gjs-cv-canvas {
        top: 0;
        width: 100%;
        height: 100%;
    }

    .gjs-block {
        width: 100%;
        height: auto;
        min-height: auto;
    }

    .panel__top {
        padding: 0;
        width: 100%;
        display: flex;
        position: initial;
        justify-content: center;
        justify-content: space-between;
        @apply mb-2
    }

    .panel__top.gjs-hidden{
        display: none;
    }

    .gjs-off-prv{
        float: right;
    }

    .panel__basic-actions {
        position: initial;
        display: flex;
    }
    .panel__basic-actions.gjs-hidden {
        display: none;
    }

    .editor-row {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
      height: calc(100% - 50px)
    }

    .editor-canvas {
        flex-grow: 1;
    }

    .panel__left {
        flex-basis: 230px;
        position: relative;
        overflow-y: auto;
      @apply  border rounded bg-white mr-2 !important
    }

    .panel__switcher {
        position: initial;
        border-radius: inherit;
    }

    .panel__devices {
        position: initial;
    }

    /* Theming */

    /* Primary color for the background */
    .gjs-one-bg {
        background-color: #ffffff00;
    }

    /* Secondary color for the text color */
    .gjs-two-color {
        color: #001930;
    }

    /* Tertiary color for the background */
    .gjs-three-bg {
        background-color: #587dec;
        color: white;
    }

    /* Quaternary color for the text color */
    .gjs-four-color,
    .gjs-four-color-h:hover {
        color: #001930;
    }

    .gjs-field-checkbox input:checked+.gjs-chk-icon{
        border-color: #001930;
    }

    /*     Custom     */
    .panel-headline{
        font-weight: bold;
    }

    .gjs-am-file-uploader{
        display: none;
    }

    .gjs-am-assets-cont{
        width: 100%;
        float: none;
    }


    .fa-trash-o:before {
      content: "\f1f8";
    }

    .gjs-title{
      @apply bg-gray-200 border-gray-400 rounded border !important
    }

    .gjs-block-category{
      @apply mb-2 border-0 !important
    }
</style>

