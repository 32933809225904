<template>
  <div>
    <h2 class="c-view-h2">{{$store.state.event.name}}</h2>
    <h1 class="c-view-h1">Landingpage</h1>
    <div v-if="$store.state.landingpages.length === 0" class="bg-white rounded border p-4">
      <p>Bitte erstellen Sie eine Landingpage:</p>
      <ui-field label="Titel der Landingpage">
        <ui-form-input v-model="landingpage.title"/>
      </ui-field>
      <ui-button @click="create()">Erstellen</ui-button>
    </div>
    <div v-else class="editor absolute">
      <GrapesJS></GrapesJS>
    </div>
  </div>
</template>

<script>
import GrapesJS from '@/components/GrapesJs/GrapesJS'
import {landingpage} from "@/store/controllers/landingpage";

export default {
  name: "Landingpage",
  components: {
    GrapesJS
  },
  data(){
    return {
      landingpage: {

      }
    }
  },
  methods: {
    async create(){
      this.landingpage.disabledAt = '1970-01-01 00:00:00'
      await this.$store.createLandingpage(this.landingpage)
    }
  },
  mounted(){
    this.$store.getLandingpages()
    this.landingpage = Object.assign(this.landingpage, landingpage)
  }
}
</script>

<style scoped>
.editor{
  top: 7.75rem;
  bottom: 2rem;
  left: 0;
  right: 0;
  @apply max-w-screen-xl mx-auto px-6
}
</style>
